import IconsUser from '~icons/icons/User.svg'
import IconsOrders from '~icons/icons/Orders.svg'
import IconsHeart from '~icons/icons/Heart.svg'
import IconsForYou from '~icons/icons/ForYou.svg'
import IconsPackaging from '~icons/icons/Packaging.svg'
import IconsWallet from '~icons/icons/Credit.svg'
import IconsFreeShipping from '~icons/icons/FreeShipping.svg'
import IconsReturns from '~icons/icons/Returns.svg'
import IconsSizes from '~icons/icons/Sizes.svg'
import IconsHelp from '~icons/icons/Help.svg'
import IconsHanger from '~icons/icons/Hanger.svg'
import IconsFile from '~icons/icons/File.svg'
import IconsFileSearch from '~icons/icons/FileSearch.svg'
import IconsCustomerCare from '~icons/icons/CustomerCare.svg'
import IconsChat from '~icons/icons/Chat.svg'
import IconsMail from '~icons/icons/Mail.svg'
import IconsWhatsapp from '~icons/icons/Whatsapp.svg'
import IconsMessenger from '~icons/icons/Messenger.svg'

import type {
  HelpIconType,
  ServicesIconType,
  ContactUsIconType,
} from '@design-system/mocks/helpIconTypes'

type IconType = 'user' | 'orders' | 'wishlist' | 'forYou'
type ProfileShortcut =
  | 'accountDetails'
  | 'address'
  | 'contactPreferences'
  | 'deleteAccount'

export const useShortcuts = () => {
  const {
    profile,
    orders,
    wishlist,
    forYou,
    accountDetails,
    address,
    deleteAccount,
    contactPreferences,
  } = useRouteHelper()

  const { ts } = useI18n()

  const iconLink: Record<IconType, string> = {
    user: profile,
    orders: orders,
    wishlist,
    forYou,
  }

  const shortcuts: Record<
    IconType,
    { icon: string; link: string; text: string }
  > = {
    user: {
      icon: IconsUser,
      link: iconLink.user,
      text: ts('myAccount.logged.shortcuts.profile'),
    },
    orders: {
      icon: IconsOrders,
      link: iconLink.orders,
      text: ts('myAccount.logged.shortcuts.orders'),
    },
    wishlist: {
      icon: IconsHeart,
      link: iconLink.wishlist,
      text: ts('myAccount.logged.shortcuts.wishlist'),
    },
    forYou: {
      icon: IconsForYou,
      link: iconLink.forYou,
      text: ts('myAccount.logged.shortcuts.forYou'),
    },
  }

  const shortcutLink: Record<ProfileShortcut, string> = {
    accountDetails: accountDetails,
    address: address,
    contactPreferences: contactPreferences,
    deleteAccount: deleteAccount,
  }

  const profileCardShortcuts: Record<
    ProfileShortcut,
    { link: string; text: string }
  > = {
    accountDetails: {
      link: shortcutLink.accountDetails,
      text: ts('myAccount.logged.overviewContent.subShortcut.accountDetails'),
    },
    address: {
      link: shortcutLink.address,
      text: ts('myAccount.logged.overviewContent.subShortcut.address'),
    },

    contactPreferences: {
      link: shortcutLink.contactPreferences,
      text: ts(
        'myAccount.logged.overviewContent.subShortcut.contactPreferences'
      ),
    },
    deleteAccount: {
      link: shortcutLink.deleteAccount,
      text: ts('myAccount.logged.profileContent.deleteAccount'),
    },
  }

  const helpShortcutsIcons: Record<HelpIconType, { icon: string }> = {
    shopping: {
      icon: IconsPackaging,
    },
    sizes: {
      icon: IconsSizes,
    },
    myAccount: {
      icon: IconsUser,
    },
    payments: {
      icon: IconsWallet,
    },
    shipments: {
      icon: IconsFreeShipping,
    },
    refunds: {
      icon: IconsReturns,
    },
    faq: {
      icon: IconsHelp,
    },
    orders: {
      icon: IconsOrders,
    },
  }

  const serviceShortcutsIcons: Record<ServicesIconType, { icon: string }> = {
    hanger: {
      icon: IconsHanger,
    },
    orders: {
      icon: IconsOrders,
    },
    fileSearch: {
      icon: IconsFileSearch,
    },
    file: {
      icon: IconsFile,
    },
  }

  const contactUsIcons: Record<ContactUsIconType, { icon: string }> = {
    phone: {
      icon: IconsCustomerCare,
    },
    chat: {
      icon: IconsChat,
    },
    email: {
      icon: IconsMail,
    },
    whatsapp: {
      icon: IconsWhatsapp,
    },
    messenger: {
      icon: IconsMessenger,
    },
    other: {
      icon: IconsHelp,
    },
  }

  // Used to not show "For You" section in go live, replace it with "shortcuts" when this computed is not needed anymore
  const myAccountTabsWithoutForYou = computed(() =>
    Object.fromEntries(
      Object.entries(shortcuts).filter(([key]) => key !== 'forYou') ?? []
    )
  )

  return {
    shortcuts,
    profileCardShortcuts,
    helpShortcutsIcons,
    serviceShortcutsIcons,
    contactUsIcons,
    myAccountTabsWithoutForYou,
  }
}
